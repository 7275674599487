import classNames from 'classnames';
import { LayoutProps } from '../interfaces/layout';
import { Header } from './Header';
import { Exit } from './icons/Exit';
import { LogoWithText } from './icons/LogoWithText';

export const UnsubscribedLayout = ({
  children,
}: LayoutProps) => {
  return (
    <div
      className={classNames(`h-full w-full`)}
    >
      <div
        className={classNames(
          'h-full w-full bg-white text-sm font-light',
          'text-darkblue-1 dark:bg-darkblue-1 dark:text-white',
          'flex flex-col'
        )}
      >
        <Header
          leftIcon={<LogoWithText />}
        />
        {children}
      </div>
    </div>
  );
};
