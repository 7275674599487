import { useQuery } from '@tanstack/react-query';
import { RouterProvider as RP } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { getIsTokenExpired } from '../utils/getIsTokenExpired';
import { getSubscription } from '../utils/api/getSubscription';
import { ROUTES } from '../consts/routes';
import { ENDPOINTS } from '../consts/endpoints';
import { URLS } from '../consts/urls';
import { AUTH } from '../consts/queryKeys';
import {
  blankRouter,
  mainRouter,
  loginRouter,
  subscribeRouter,
} from '../utils/routers';

async function handleAuth() {
  var queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  if (urlParams.get('authorizated')) {
    const cookies = new Cookies(null, { path: '/' });
    let sid = cookies.get('sid');
    let body = {
      sid: sid,
    };
    let headers = {
      'Content-Type': 'application/json',
    };
    let response = await fetch(
      `${URLS.MANUSCRIPTIFY_API_URL}${ENDPOINTS.VALIDATE_SESSION}`,
      {
        method: 'POST',
        headers,
        body: JSON.stringify(body),
      }
    );
    if (response.ok) {
      let { bearer } = await response.json();
      cookies.set('token', bearer);
      window.location.replace(ROUTES.ROOT);
    }
  } else if (urlParams && Object.keys(urlParams).length > 0) {
    console.log('redirecting to root...');
    window.location.replace(ROUTES.ROOT);
  }
}

handleAuth();
// maybe put an error boundary here or in App.tsx to smooth the login-redirect workflow


export const RouterProvider = () => {
  const { data: subs, isLoading, isError, error } = useQuery({
    queryKey: [AUTH],
    queryFn: getSubscription,
  });

  if (isLoading) {
    return <RP router={blankRouter} />;
  }

  if (isError || subs===undefined) {
    console.log(`error: ${error}`);
    return <RP router={loginRouter} />;
  }

  if (subs && subs.valid) {
    return <RP router={mainRouter} />;
  } else {
    const props = {
      freeLabel: subs.freeLabel,
      monthlyPaymentUrl: subs.monthlyPaymentUrl,
      annualPaymentUrl: subs.annualPaymentUrl,
    }
    return <RP router={subscribeRouter(props)} />;
  }
};
