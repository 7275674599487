import classNames from 'classnames';
import { SubscribeProps } from '../interfaces/router'

export const Subscribe = ({
  freeLabel,
  monthlyPaymentUrl,
  annualPaymentUrl
}: SubscribeProps) => {
  return (
    <div className="h-full w-full flex flex-col mx-auto p-5 bg-white font-light text-darkblue-1 dark:bg-darkblue-1 dark:text-white">
      <section className="h-full mt-3 flex flex-col items-center px-5">

        <div className="flex w-[70%] mt-auto bg-violet-1 rounded-3xl p-1 pb-6 text-center text-xl">
          <div className="w-full">
            <div className="bg-darkblue-2 border border-violet-4 rounded-3xl">
              <div className="">
                <h5 className="pt-3">Monthly</h5>
                <h2 className="py-5 text-4xl">$2</h2>
              </div>
            </div>
            <p className="p-8">Not bad value</p>
            <a
              href={monthlyPaymentUrl}
              className={classNames(
                'bg-white rounded-full text-violet-1',
                'uppercase px-4 py-2 hover:bg-violet-4'
              )}
            >
              get this
            </a>
          </div>
        </div>

        <div className="flex w-[70%] bg-cyan-2 rounded-3xl p-1 pb-6 text-center text-xl my-8">
          <div className="w-full">
            <div className="bg-cyan-1 border border-cyan-3 rounded-3xl">
              <div className="">
                <h5 className="pt-3">Annual</h5>
                <h2 className="py-5 text-4xl">$18</h2>
              </div>
            </div>
            <p className="p-8">Awesome value</p>
            <a
              href={annualPaymentUrl}
              className={classNames(
                'bg-white rounded-full text-cyan-2',
                'uppercase px-4 py-2 hover:bg-cyan-3'
              )}
            >
              get this
            </a>
          </div>
        </div>

        <div className="flex flex-col mb-auto w-[70%] text-lightgray-1 px-5">
          <div>Prices in USD.</div>
          {freeLabel ? (
            <div>
              {freeLabel}
            </div>
          ) : null}
          <div>Cancel at any time.</div>
        </div>
      </section>
    </div>
  );
};
