import classNames from 'classnames';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LayoutProps } from '../interfaces/layout';
import { Header } from './Header';
import { Exit } from './icons/Exit';
import { LogoWithText } from './icons/LogoWithText';
import { Settings } from './icons/Settings';
import { ROUTES } from '../consts/routes';

export const SubscribedLayout = ({
  children,
}: LayoutProps) => {
  const navigate = useNavigate();
  return (
    <div
      className={classNames(`h-full w-full`)}
    >
      <div
        className={classNames(
          'h-full w-full bg-white text-sm font-light',
          'text-darkblue-1 dark:bg-darkblue-1 dark:text-white',
          'flex flex-col'
        )}
      >
        <Header
          leftIcon={<LogoWithText />}
          rightIcon={Settings('#5452A5')}
          onRightIconClick={() => navigate(ROUTES.GLOBAL_SETTINGS)}
        />
        {children}
      </div>
    </div>
  );
};
