import React from 'react';
import classNames from 'classnames';
import { RouterProvider } from './components/RouterProvider';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './utils/queryClients';
import { SubscribedLayout } from './components/SubscribedLayout';
import './output.css';

function App() {
  return (
    <div
      className={classNames(`dark h-full w-[370px] md:w-[640px] border-x border-violet-1`)}
    >
      <React.StrictMode>
        <QueryClientProvider client={queryClient}>
          <RouterProvider />
        </QueryClientProvider>
      </React.StrictMode>
    </div>
  );
}

export default App;
