import { SortableTree, TreeItems } from 'dnd-kit-sortable-tree';
import { PointerSensorOptions } from '@dnd-kit/core';
import { useState } from 'react';
import { Footer } from './Footer';
import { FolderTreeNode } from '../../interfaces/projects';
import Switch from '../Switch';
import {
  checkIfAllChildrenChecked,
  checkIfAnyTreeItemChildChecked,
  checkItem,
  convertFolderTreeNodesToTreeItems,
  getCheckedIds,
  getTreeItem,
} from '../../utils/folderTree';
import {
  MinimalTreeItemData,
  MoveFileData,
} from '../../interfaces/folderTree';
import { useMutation } from '@tanstack/react-query';
import { moveFile } from '../../utils/api/moveFile';
import { ItemChangedReason } from 'dnd-kit-sortable-tree/dist/types';
import { ErrorPanel } from '../ErrorPanel';
import { queryClient } from '../../utils/queryClients';
import { PROJECT_FOLDER } from '../../consts/queryKeys';

interface DetailProps {
  generateManuscript: (ids: string[], isFinal: boolean, isWorkshopDraft: boolean) => void;
  treeNode: FolderTreeNode;
  projectId: string;
  showLoading: () => void;
  hideLoading: () => void;
  isMutationLoading?: boolean;
}

const findInsertAfterId = (
  items: TreeItems<MinimalTreeItemData>,
  folderId: string
): string | null | undefined => {
  const droppedItemIndex = items.findIndex(
    (item) => item.folderId === folderId
  );
  let result;
  if (droppedItemIndex != -1) {
    result = droppedItemIndex ? items.at(droppedItemIndex - 1)?.folderId : null;
    return result;
  } else {
    for (const item of items) {
      if (item.canHaveChildren && item.children) {
        result = findInsertAfterId(item.children, folderId);
        if (!(result === undefined)) {
          return result;
        }
      }
    }
  }
};

export const Detail = ({
  generateManuscript,
  treeNode,
  projectId,
  hideLoading,
  showLoading,
  isMutationLoading,
}: DetailProps) => {
  const { mutate } = useMutation({
    mutationFn: moveFile,
    onMutate: () => {
      showLoading();
    },
    onSuccess: async (response, variables) => {
      if (!response.ok) {
        setItems(variables.oldItems);
        setIsErrorVisible(true);
        return;
      }
      setIsErrorVisible(false);
      const newItems = (await response.json()) as FolderTreeNode;
      queryClient.setQueryData([PROJECT_FOLDER, projectId], newItems);
    },
    onSettled: () => {
      hideLoading();
    },
  });

  const [items, setItems] = useState(
    convertFolderTreeNodesToTreeItems(treeNode.children || [])
  );
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const isButtonDisabled = !checkIfAnyTreeItemChildChecked(items);

  const handleCheckItem = (id: string) => {
    const newItems = checkItem(id, items);
    setItems(newItems);

    if (checkIfAllChildrenChecked(newItems)) {
      setIsCheckedAll(true);
    } else {
      setIsCheckedAll(false);
    }
  };

  const handleCheckAll = () => {
    items.forEach((item) => {
      setItems((prev) => checkItem(item.folderId, prev, !isCheckedAll));
    });
    setIsCheckedAll((prev) => !prev);
  };

  const handleGenerateManuscript = (isFinal: boolean, isWorkshopDraft: boolean) => {
    const ids = getCheckedIds(items);
    if (isCheckedAll) {
      ids.length = 0;
    }
    generateManuscript(ids, isFinal, isWorkshopDraft);
  };

  const handleTreeChange = (
    newItems: TreeItems<MinimalTreeItemData>,
    reason: ItemChangedReason<MinimalTreeItemData>
  ) => {
    if (reason.type === 'dropped') {
      const data: MoveFileData = {
        insertAfterId: reason.droppedToParent?.folderId || projectId,
        itemId: reason.draggedItem.folderId,
        parentId: reason.droppedToParent?.folderId || projectId,
        projectId,
        oldItems: items,
      };

      const insertAfterId = findInsertAfterId(
        newItems,
        reason.draggedItem.folderId
      );
      if (insertAfterId) data.insertAfterId = insertAfterId;
      mutate(data);
    }
    setItems(newItems);
  };

  return (
    <>
      {isErrorVisible && (
        <ErrorPanel error="Sorry, we were not able to move the file in desired place. Please try again later." />
      )}
      <header className="px-5 pt-4 mb-4 mt-2 flex justify-between">
        <div className="flex items-center">
          {/* No API for this yet 
          <span className="ml-3">
            <NewFilesLabel newFilesCount={treeNode.newFilesCount} />
          </span> */}
        </div>
        <div className="flex">
          <Switch checked={isCheckedAll} onChange={handleCheckAll} />
          <span className="medium-14 ml-3">Select all</span>
          <span className="medium-14 ml-1 text-lightgray-1">
            ({treeNode.children?.length || 0})
          </span>
        </div>
      </header>
      <section className="px-5 max-h-[calc(100%-200px)] overflow-auto">
        <div className="rounded-lg border py-1 dark:border-violet-1 dark:bg-darkblue-2">
          <SortableTree
            items={items}
            onItemsChanged={handleTreeChange}
            TreeItemComponent={getTreeItem(handleCheckItem)}
            indentationWidth={33}
          />
        </div>
      </section>
      <Footer
        isButtonDisabled={isButtonDisabled}
        isMutationLoading={Boolean(isMutationLoading)}
        generateManuscript={handleGenerateManuscript}
      />
    </>
  );
};
