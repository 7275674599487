interface HeaderProps {
  leftIcon: JSX.Element;
  rightIcon?: JSX.Element;
  onLeftIconClick?: () => void;
  onRightIconClick?: () => void;
}

export const Header = ({
  leftIcon,
  rightIcon,
  onLeftIconClick,
  onRightIconClick,
}: HeaderProps) => {
  return (
    <header className="flex items-center justify-between border-b border-violet-1 py-4 px-5">
      {onLeftIconClick ? (
        <button
          title="manuscriptify logo"
          type="button"
          onClick={onLeftIconClick}
        >
          {leftIcon}
        </button>
      ) : (
        leftIcon
      )}
      {rightIcon ? (
        <button
          title="Global settings"
          type="button"
          onClick={onRightIconClick}
        >
          {rightIcon}
        </button>
      ) : null }
    </header>
  );
};
