import { SplitButton } from '../SplitButton';

interface FooterProps {
  isButtonDisabled: boolean;
  generateManuscript: (final: boolean, draft: boolean) => void;
  isMutationLoading: boolean;
}

export const Footer = ({
  isButtonDisabled,
  generateManuscript,
  isMutationLoading,
}: FooterProps) => {
  return (
    <footer className="flex flex-col mt-auto w-full border-t-2 border-primary p-4">
      <div className="flex items-center justify-center">
        <SplitButton
          disabled={isButtonDisabled || isMutationLoading}
          isLoading={isMutationLoading}
          onClick={() => generateManuscript(false, false)}
          menuItems={[
            {
              label: 'Final manuscript',
              handleClick: (e) => {
                e.stopPropagation();
                generateManuscript(true, false);
              },
            },
            {
              label: 'Workshop draft',
              handleClick: (e) => {
                e.stopPropagation();
                generateManuscript(false, true);
              },
            },
          ]}
        >
          <span>Generate manuscript</span>
        </SplitButton>
      </div>
    </footer>
  );
};
