import { ENDPOINTS } from '../../consts/endpoints';
import { URLS } from '../../consts/urls';
import { SubscriptionResponse } from '../../interfaces/api';
import { authorizatedFetcher } from './authorizatedFetcher';

export const getSubscription = async () => {
  const response = await authorizatedFetcher(
    `${URLS.MANUSCRIPTIFY_API_URL}${ENDPOINTS.SUBSCRIPTION_STATUS}`
  );
  try {
    const data: SubscriptionResponse = await response.json();
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};
