import { createMemoryRouter } from 'react-router-dom';
import { SubscribedLayout } from '../components/SubscribedLayout';
import { UnsubscribedLayout } from '../components/UnsubscribedLayout';
import { Projects } from '../components/projects/Projects';
import { Project } from '../components/project/Project';
import { Browse } from '../components/browse/Browse';
import { AddProject } from '../components/AddProject';
import { ProjectSettings } from '../components/ProjectSettings';
import { GlobalSettings } from '../components/GlobalSettings';
import { Login } from '../components/Login';
import { Subscribe } from '../components/Subscribe';
import { MainProps, SubscribeProps } from '../interfaces/router';
import { ROUTES } from '../consts/routes';

const BlankPanel = () => (
  <UnsubscribedLayout>
    <>
    </>
  </UnsubscribedLayout>
);

const LoginPanel = () => (
  <UnsubscribedLayout>
    <Login />
  </UnsubscribedLayout>
);

const SubscribePanel = ({
  freeLabel,
  monthlyPaymentUrl,
  annualPaymentUrl
}: SubscribeProps) => (
  <UnsubscribedLayout>
    <Subscribe
      freeLabel={freeLabel}
      monthlyPaymentUrl={monthlyPaymentUrl}
      annualPaymentUrl={annualPaymentUrl}
    />
  </UnsubscribedLayout>
);

const MainPanel = ({Page}: MainProps) => (
  <SubscribedLayout>
    <Page />
  </SubscribedLayout>
);

export const blankRouter = createMemoryRouter(
  [
    { path: '/', element: <BlankPanel /> },
    { path: '/*', element: <BlankPanel /> }
  ],
  { initialEntries: ['/'] }
);

export const loginRouter = createMemoryRouter(
  [
    { path: ROUTES.LOGIN, element: <LoginPanel /> },
    { path: '/*', element: <LoginPanel /> }
  ],
  { initialEntries: [ROUTES.LOGIN] }
);

export const subscribeRouter = ({
  freeLabel,
  monthlyPaymentUrl,
  annualPaymentUrl
}: SubscribeProps) => {
  return createMemoryRouter(
    [
      {
        path: '/*',
        element: <SubscribePanel
          freeLabel={freeLabel}
          monthlyPaymentUrl={monthlyPaymentUrl}
          annualPaymentUrl={annualPaymentUrl}
        />
      },
    ]
  );
}

export const mainRouter = createMemoryRouter(
  [
    { path: ROUTES.PROJECTS, element: <MainPanel Page={Projects} /> },
    { path: ROUTES.PROJECT, element: <MainPanel Page={Project} /> },
    { path: ROUTES.BROWSE, element: <MainPanel Page={Browse} /> },
    { path: ROUTES.ADD_PROJECT, element: <MainPanel Page={AddProject} /> },
    { path: ROUTES.PROJECT_SETTINGS, element: <MainPanel Page={ProjectSettings} /> },
    { path: ROUTES.GLOBAL_SETTINGS, element: <MainPanel Page={GlobalSettings} /> },
    { path: '/*', element: <MainPanel Page={Projects} /> },
    /*
    {
      path: PANEL_ROUTES.WELCOME_PAGE,
      element: <WelcomePage />,
    },
    */
  ],
  { initialEntries: [ROUTES.PROJECTS] }
);
