import Cookies from 'universal-cookie';
import { HTTP_RESPONSES } from '../../consts/httpResponses';
import { TOKEN_ERROR_DETAIL } from '../../consts/tokenErrorDetail';
import { getToken } from '../getToken';
import { refreshToken } from './refreshToken';

export const authorizatedFetcher = async (
  url: string,
  options: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }
): Promise<Response> => {
  const token = await getToken();

  if (Boolean(token)) {
    const headers = {
      ...options.headers,
      Authorization: `Bearer ${token}`,
    };
    var response = await fetch(url, {
      ...options,
      headers,
    });

    if (response.status === HTTP_RESPONSES.UNAUTHORIZED) {
      const data = await response.json();

      if (data.detail === TOKEN_ERROR_DETAIL.TOKEN_INVALID) {
        return Promise.reject(new Error('Token invalid (cannot be refreshed)'));
      }

      if (data.detail === TOKEN_ERROR_DETAIL.TOKEN_EXPIRED) {
        try {
          response = await refreshToken();
          if (response.ok) {
            const { bearer } = await response.json();
            const cookies = new Cookies(null, { path: '/' });
            cookies.set('token', bearer);
            return await authorizatedFetcher(url, options);
          } else {
            return Promise.reject(new Error('Token refresh not OK'));
          }
        } catch (error) {
          return Promise.reject(new Error('Error when refreshing token'));
        }
      }

      return Promise.reject(new Error('Unauthorized in some other way'));

    }

    return response;
  }

  throw new Error('No token');
};
