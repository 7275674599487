import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getUserProfile } from '../utils/api/getUserProfile';
import { USER_PROFILE } from '../consts/queryKeys';
import { updateUserProfile } from '../utils/api/updateUserProfile';
import Switch from './Switch';
import { Navbar } from './Navbar';
import { Button } from './Button';
import { Input } from './Input';
import { ErrorPanel } from './ErrorPanel';
import { Spinner } from './icons/Spinner';
import { UserProfile, SaveUserProfile } from '../interfaces/userProfile';
import { MessagePanel } from './MessagePanel';

export const GlobalSettings = () => {
  const isDarkMode = true;

  const navigate = useNavigate();

  const [street1, setStreet1] = useState('');
  const [street2, setStreet2] = useState('');
  const [replyTo, setReplyTo] = useState('');
  const [replyEmail, setReplyEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [defaultPseudonym, setDefaultPseudonym] = useState('');
  const [defaultGenre, setDefaultGenre] = useState('');
  const [defaultCategory, setDefaultCategory] = useState('');
  const [defaultChapterDepth, setDefaultChapterDepth] = useState('');
  const [isChecked, setIsChecked] = useState(true);

  const { data, isLoading, isError } = useQuery({
    queryKey: [USER_PROFILE],
    queryFn: getUserProfile
  });

  const {
    mutate,
    isPending: isUpdating,
    isSuccess: isMutateSuccess,
    isError: isMutateError,
  } = useMutation({
    mutationFn: updateUserProfile,
  });

  useEffect(() => {
    if (data) {
      setStreet1(data.street1);
      setStreet2(data.street2 || '');
      setReplyTo(data.replyTo);
      setReplyEmail(data.replyEmail);
      setPhone(data.phone);
      setDefaultPseudonym(data.defaultPseudonym || '');
      setDefaultGenre(data.defaultGenre || '');
      setDefaultCategory(data.defaultCategory || '');
      if (! (data.defaultChapterDepth === undefined)) {
        setDefaultChapterDepth(String(data.defaultChapterDepth));
      }
      setIsChecked(data.autorenew);
    }
  }, [data]);

  if (isLoading) return <LoadingView />;
  if (!data || isError) return <ErrorView />;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    switch (name) {
      case 'street1':
        setStreet1(value);
        break;
      case 'street2':
        setStreet2(value);
        break;
      case 'replyTo':
        setReplyTo(value);
        break;
      case 'replyEmail':
        setReplyEmail(value);
        break;
      case 'phone':
        setPhone(value);
        break;
      case 'defaultPseudonym':
        setDefaultPseudonym(value);
        break;
      case 'defaultGenre':
        setDefaultGenre(value);
        break;
      case 'defaultCategory':
        setDefaultCategory(value);
        break;
      case 'defaultChapterDepth':
        setDefaultChapterDepth(value);
        break;
      default:
        break;
    }
  };

  const handleCheck = () => {
    setIsChecked((prev) => !prev);
  };

  const handleUpdateUserProfile = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data: SaveUserProfile = {
      autorenew: isChecked,
      phone: phone || '',
      replyEmail: replyEmail || '',
      replyTo: replyTo || '',
      street1: street1 || '',
      street2: street2 || '',
      defaultPseudonym: defaultPseudonym || '',
      defaultGenre: defaultGenre || '',
      defaultCategory: defaultCategory || '',
    };
    if (!!defaultChapterDepth) {
      data.defaultChapterDepth = Number(defaultChapterDepth);
    }
    mutate(data);
  };

  const expiry = new Date(Date.parse(data.expiry));

  return (
    <>
      <Navbar
        title="Update global settings"
        quicklinkLabel="Go back"
      />
      {isMutateError && (
        <ErrorPanel error="Sorry, we were unable to update your details." />
      )}
      {isMutateSuccess && <MessagePanel message="Details updated!" />}
      <div className="max-h-[calc(100%-180px)] m-5 overflow-auto">
        <form onSubmit={handleUpdateUserProfile}>
          <div className="w-full flex flex-col justify-between">
            <div>
              <h2 className="mb-1">Subscription...</h2>
              <div className="mb-2">
                Expires: {expiry.toDateString()}
              </div>
              <div className="flex justify-between mb-4">
                <span className="inline-block">
                  autorenew
                </span>
                <span className="inline-block">
                  <Switch checked={isChecked} onChange={handleCheck} />
                </span>
              </div>
            </div>
            <div className="border-t border-t-violet-2 pt-6">
              <h2 className="mb-1">
                Contact details for literary correspondence...
              </h2>
              <div className="mb-4">
                These will be placed on the title page when generating a final manuscript
              </div>
            </div>
            <Input
              name="replyTo"
              label="Name"
              value={replyTo}
              onChange={handleInputChange}
            />
            <Input
              name="street1"
              label="Address line 1"
              value={street1}
              onChange={handleInputChange}
            />
            <Input
              name="street2"
              label="Address line 2"
              value={street2}
              onChange={handleInputChange}
            />
            <Input
              name="replyEmail"
              label="Email"
              value={replyEmail}
              onChange={handleInputChange}
            />
            <Input
              name="phone"
              label="Phone"
              value={phone}
              onChange={handleInputChange}
            />
            <div className="border-t border-t-violet-2 pt-6">
              <h2 className="mb-1">
                Default values...
              </h2>
              <div className="mb-4">
                These can be overridden for specific
                projects in Project Settings
              </div>
            </div>
            <Input
              name="defaultPseudonym"
              label="Pseudonym"
              value={defaultPseudonym}
              onChange={handleInputChange}
            />
            <Input
              name="defaultGenre"
              label="Genre"
              value={defaultGenre}
              onChange={handleInputChange}
            />
            <Input
              name="defaultCategory"
              label="Category"
              value={defaultCategory}
              onChange={handleInputChange}
            />
            <Input
              name="defaultChapterDepth"
              label="Chapter depth"
              value={defaultChapterDepth}
              onChange={handleInputChange}
            />
          </div>
          <div className="flex-col mt-6">
            <Button buttonType="submit" isLoading={isUpdating}>
              <span className="text-nowrap">save changes</span>
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

const LoadingView = () => {
  return (
    <>
      <Navbar
        title="Update global settings"
        quicklinkLabel="Go back"
      />
      <section className="py-4">
        <header className="medium-12 px-5">
          Contact details for literary correspondence:
        </header>
        <div className="flex justify-center py-10">
          <Spinner />
        </div>
      </section>
    </>
  );
};

const ErrorView = () => {
  return (
    <>
      <Navbar
        title="Update global settings"
        quicklinkLabel="Go back"
      />
      <section className="py-4">
        <header className="medium-12 px-5">
          Contact details for literary correspondence:
        </header>
        <ErrorPanel error="Sorry, we were unable to fetch project settings." />
      </section>
    </>
  );
};
