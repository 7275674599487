import { ENDPOINTS } from '../../consts/endpoints';
import { URLS } from '../../consts/urls';
import { SaveUserProfile } from '../../interfaces/userProfile';
import { userProfileAdapter } from '../adapters';
import { authorizatedFetcher } from './authorizatedFetcher';

export const updateUserProfile = async (data: SaveUserProfile) => {
  await authorizatedFetcher(
    `${URLS.MANUSCRIPTIFY_API_URL}${ENDPOINTS.UPDATE_USER_PROFILE}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userProfileAdapter(data)),
    }
  );
};
