import { ENDPOINTS } from '../../consts/endpoints';
import { URLS } from '../../consts/urls';
import { authorizatedFetcher } from './authorizatedFetcher';

interface GenerateManuscriptData {
  projectId: string;
  folderIds: string[];
  isFinal: boolean;
  isWorkshopDraft: boolean;
}

export const generateManuscript = async (data: GenerateManuscriptData) => {
  const body = {
    project_id: data.projectId,
    selection: data.folderIds ? data.folderIds : undefined,
    final: data.isFinal,
    workshop_draft: data.isWorkshopDraft,
  };

  if (!Boolean(body['selection'])) {
    delete body['selection'];
  }

  const response = await authorizatedFetcher(
    `${URLS.MANUSCRIPTIFY_API_URL}${ENDPOINTS.GENERATE_MANUSCRIPT}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }
  );
  const responseData = (await response.json()) as {
    id: string;
    quote_text: string;
    quote_author: string;
    quote_book?: string;
    quote_character?: string;
  };
  return responseData;
};
