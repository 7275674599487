export const ROUTES = {
  ADD_PROJECT: '/project/add',
  ANNUAL: '/annual',
  BROWSE: '/browse',
  GLOBAL_SETTINGS: '/settings',
  INVALID_TOKEN: '/invalid-token',
  LOGIN: '/login',
  MONTHLY: '/monthly',
  ONBOARDING: '/onboarding',
  PROJECT: '/project',
  PROJECTS: '/projects',
  PROJECT_SETTINGS: '/project/settings',
  ROOT: '/',
  SETTINGS: '/settings',
  SUBSCRIBE: '/subscribe',
  WELCOME: '/welcome',
} as const;
